import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeaderTop from "../components/HeaderTop"
import HeaderColor from "../components/HeaderColor"
import Footer from "../components/Footer"
import CallToAction from "../components/CallToAction"
import options from "../../config/options"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const PageWrapper = styled.div`
  margin: 80px 0;
  h1 {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 38px;
    color: #323232;
    margin-bottom: 20px;
    text-align: center;
  }
  p {
    font-family: "Prompt", sans-serif;
    font-weight: 400;
    color: #848383;
  }
  u,
  strong {
    font-weight: 500;
    color: #323232;
  }
`

const CookiesPolicyPage = () => {
  return (
    <Layout>
      <SEO
        title="นโยบายข้อมูลส่วนบุคคลสําหรับการใช้คุกกี้ | AEC brand | ปั๊มหอยโข่งโซล่าเซลล์ ปั๊มบาดาลโซล่าเซลล์"
        description="นโยบายข้อมูลส่วนบุคคลสําหรับการใช้คุกกี้ | AEC brand | ปั๊มหอยโข่งโซล่าเซลล์ ปั๊มบาดาลโซล่าเซลล์ ปั๊มโซล่าเซลล์ ระบบ Off Grid โซล่าเซลล์ สำหรับใช้กับไฟบ้าน และเพื่อการเกษตร"
      />
      <HeaderTop text={options.headerTop} />
      <HeaderColor />
      <PageWrapper>
        <div className="gb-container">
          <Row className="rowContent">
            <Col span={24}>
              <h1>นโยบายข้อมูลส่วนบุคคลสําหรับการใช้คุกกี้</h1>
              <div className="contentWrapper">
                <p>
                  <strong>บริษัท เออีซีเอกซ์พอร์ต จำกัด (“บริษัท”)</strong>{" "}
                  มีความจําเป็นต้องใช้คุกกี้ในการทํางานหลายส่วนของเว็บไซต์เพื่อรับประกันการให้บริการของเว็บไซต์ที่จะอํานวยความ
                  สะดวกในการใช้บริการเว็บไซต์ของท่าน
                  โดยบริษัทรับประกันว่าจะใช้คุกกี้เท่าที่จําเป็น
                  และมีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลของท่านโดยสอดคล้องกับ
                  กฎหมายที่เกี่ยวข้อง
                  และจะไม่เปิดเผยข้อมูลดังกล่าวให้แก่บุคคลอื่น
                  เว้นแต่เป็นกรณีการใช้คุกกี้บางประเภทที่อาจดําเนินการโดยผู้ให้บริการภายนอก
                  ทั้งนี้ เมื่อท่าน เข้าใช้บริการเว็บไซต์
                  บริษัทจะถือว่าท่านรับทราบและตกลงนโยบายข้อมูลส่วนบุคคลฉบับนี้แล้ว
                  โดยบริษัทสงวนสิทธิในการปรับปรุงนโยบายฉบับนี้ตามแต่ละระยะ
                  เวลาที่บริษัทเห็นสมควร
                  โดยบริษัทจะแจ้งให้ท่านทราบถึงการเปลี่ยนแปลงดังกล่าวผ่านทางเว็บไซต์นี้
                </p>
                <br />
                <p>
                  <u>ประเภทและวัตถุประสงค์การใช้คุกกี้ของบริษัท</u>{" "}
                  บริษัทมีความจําเป็นต้องใช้คุกกี้หลายประเภทเพื่อจุดประสงค์ที่แตกต่างกันไป
                  โดยแบ่งออกเป็น 4 ประเภท ดังต่อไป นี้
                </p>
                <p>
                  1. Strictly Necessary Cookies
                  ซึ่งเป็นคุกกี้ประเภทที่มีความจําเป็นอย่างมากต่อการทํางานให้บริการของเว็บไซต์แก่ผู้ใช้บริการ
                </p>
                <p>
                  2. Functionality Cookies
                  ซึ่งเป็นคุกกี้ประเภทที่จดจําสิ่งที่ผู้ใช้บริการเลือกหรือตั้งค่าบนเว็บไซต์เช่น
                  ชื่อบัญชีผู้ใช้ ภาษา ฟ้อนต์และรูปแบบการนําเสนอ ข้อมูลต่าง ๆ
                  ที่ตรงความต้องการเฉพาะบุคคลให้แก่ผู้ใช้บริการได้มากขึ้นตามการตั้งค่าที่เลือกไว้
                </p>
                <p>
                  3. Performance Cookies
                  ซึ่งเป็นคุกกี้ประเภทที่ประเมินประสิทธิภาพในการทํางานแต่ละส่วนของเว็บไซต์
                  ทั้งนี้ คุกกี้ประเภทดังกล่าวอาจดําเนินการโดย
                  ผู้ให้บริการภายนอก และ
                </p>
                <p>
                  4. Advertising Cookies
                  ซึ่งเป็นคุกกี้ประเภทที่ใช้เพื่อการนําเสนอสินค้าหรือบริการที่เกี่ยวข้องและตรงกับความสนใจของผู้ใช้บริการ
                  ทั้งนี้ คุกกี้ประเภทนี้ อาจดําเนินการโดยผู้ให้บริการภายนอก
                </p>
                <p>
                  สําหรับคุกกี้ประเภทอื่นนอกเหนือจาก Strictly Necessary Cookies
                  หากได้รับความยินยอมจากท่าน
                  บริษัทจะใช้คุกกี้ดังกล่าวเพื่อวัตถุประสงค์เฉพาะที่ได้ระบุไว้
                </p>
                <br />
                <p>
                  <u>สิทธิของเจ้าของข้อมูล</u>
                </p>
                <p>
                  บริษัทรับทราบ และเคารพสิทธิของท่านในฐานะเจ้าของข้อมูลตามกฎหมาย
                  โดยเฉพาะสิทธิถอนความยินยอมได้ทางหน้าเว็บไซต์ และท่าน
                  สามารถลบการตั้งค่าคุกกี้บน Browser ของตนเองได้เช่นกัน
                  หรือหากมีข้อสงสัยสามารถติดต่อบริษัทได้ที่: 0844441494
                  หรืออีเมล SolarBear.Thailand@gmail.com
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </PageWrapper>
      <Footer />
      <CallToAction />
    </Layout>
  )
}

export default CookiesPolicyPage
